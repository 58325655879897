<template>
  <div class="content">
    <PageHeader :title="$t('contact-list-component.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <form @submit.prevent="fetch(1)">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 to-left">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <router-link to="/contacts/import" class="btn btn-info">
                          <i class="icon dripicons-upload"></i
                          ><span class="hide-m">{{$t('generic-str.import')}}</span>
                        </router-link>
                        <button
                          type="button"
                          v-modal="{ target: 'create-contact' }"
                          class="btn btn-success emit-j-event"
                        >
                          <i class="icon dripicons-plus"></i
                          ><span class="hide-m">{{$t('generic-str.lbl-new-contact')}}</span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-remove border-radius-right"
                          @click="deleteAll"
                          :class="{ disabled: selectedContacts.length == 0 }"
                        >
                          <i class="icon dripicons-trash color-white"></i
                          ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 input-group">
                    <input
                      type="text"
                      class="form-control to-left"
                      :placeholder="$t('generic-str.search-by.name')"
                      v-model="form.q"
                    />
                    <button
                      type="submit"
                      class="btn btn-danger to-right btn-search"
                    >
                      {{$t('generic-str.filter')}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!contacts.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i class="far fa-edit font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
                  <p>{{$t('warnings.register-user')}}</p>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="contacts.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllContacts"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col">{{$tc('generic-str.lbl-number', 1)}}</th>
                      <th scope="col">Email</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(contact, i) in contacts" :key="contact.id">
                      <td data-label>
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedContacts"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'contact' + i"
                            :value="contact"
                          />
                          <label
                            class="custom-control-label"
                            :for="'contact' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="Nome">
                        {{ contact.first_name }} {{ contact.last_name }}
                      </td>
                      <td data-label="Número">
                        {{ contact.mobile_number | phone }}
                      </td>
                      <td data-label="Email">{{ contact.email }}</td>
                      <td data-label>
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style
                          >
                            <a
                              class="dropdown-item emit-j-even"
                              @click="showContactModal(contact, 'contact-modal')"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a class="dropdown-item" @click="remove(contact.id)"
                              >{{$t('generic-str.remove')}}</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal
      id="create-contact"
      @sent="fetch(form.page)"
    ></create-contact-modal>
    <contact-modal id="contact-modal" @sent="fetch(form.page)"></contact-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import ContactService from '@/services/contact.service';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import ContactModal from '@/components/contacts/ContactModal.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

export default {
  name: 'ListContact',
  components: {
    PageHeader,
    Pagination,
    CreateContactModal,
    ContactModal,
  },
  data() {
    return {
      fetched: false,
      contacts: [],
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
      selectedContacts: [],
    };
  },
  computed: {
    selectAllContacts: {
      get() {
        if (this.contacts) {
          return this.selectedContacts.length === this.contacts.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.contacts.forEach((contact) => {
            selected.push(contact);
          });
        }

        this.selectedContacts = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    showContactModal(item, id) {
      item.birthdate = moment(item.birthdate).format('DD/MM/YYYY');
      this.$root.$emit('show.modal', id, item);
    },
    searchContacts(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.searchContacts(this.form, this.form.name).then(
        (response) => {
          this.fetched = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.getContacts(this.form).then(
        (response) => {
          this.fetched = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('domain.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteContact(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('contact-list-component.remove'),
        text: this.$t('contact-list-component.remove-label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedContacts.forEach((item) => {
            toDelete.push(item.id);
          });
          ContactService.deleteContacts({ ids: toDelete }).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                this.$t('contact-list-component.deleted-2'),
                'success',
              );
              this.selectedContacts = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  margin-left: 8px;
}
</style>
